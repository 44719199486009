import request from './request'

const menu = {
    postLogin(params) {
        return request({
            method: 'POST',
            url: '/login-center/agent/pc/login',
            data: params
        })
    },
    // 退出登录
    logout(params) {
        return request({
            method: 'POST',
            url: '/login-center/agent/pc/logout',
            data: params
        })
    },
    //首页
    //首页订单列表
    HomeOrderList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/statistics/agentOrderRankList',
            data: params,
        })
    },
    // 门店首页订单列表
    storeHomeOrderList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/excelStatisticsRank',
            data:params,
            responseType:'blob'
        })
    },
     // 门店首页门店商家-交易排行
     platformOrderRankListExcel(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/statistics/platformOrderRankListExcel',
            data:params,
            responseType:'blob'
        })
    },
    // 门店订单统计

    storeHomeOrder(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getStatisticsRank',
            data: params,
        })
    },
     //验机报告
     getInspectionReport(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/getInspectionReport',
            params
        })
    },
    //首页数据
    HomeData(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/statistics/agentDataStatistics',
            data: params,
        })
    },
    // 门店首页数据
    storeHomeData(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/dataStatistics',
            data: params,
        })
    },
    // 总监区域
    inspectorAreaSelect(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/company/inspectorAreaSelect',
            params,
        })
    },
    //首页图表
    echartsList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/statistics/agentStatisticsOrderList',
            data: params,
        })
    },
    // 订单状态下拉列表
    orderStatusList(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/orderStateList',
            params: params,
        })
    },
    //订单列表
    orderList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/order/list',
            data: params,
        })
    },
    // 订单列表-所有报价商
    orderStoreList(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/listQuoteByOrderNo',
            params,
        })
    },
    // 订单动态
    orderFlow(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/orderFlow',
            params,
        })
    },
    //订单详情
    orderInfo(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/info',
            params: params,
        })
    },
    //合作商家下拉查询列表数据
    SelectCompany(params) {
        return request({
            method: 'GET',
            url: '/merchant/pc/merchantUnionCompany/getMiddleAdoptCompany',
            params: params,
        })
    },
    //导航菜单
    getMenuList(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/role/menu',
            params,
        })
    },
    //获取配置
    ResourcesConfig(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/config/get',
            data: params,
        })
    },
    //修改配置
    ResourcesEdit(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/config/modify',
            data: params,
        })
    },
    //门店商家
    //商家列表
    companyList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/matchChannelAccount/companyList',
            data: params,
        })
    },
    //职员列表
    getStaffList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getStaffList',
            data: params,
        })
    },
    // 员工列表
    getStaffPreson(params){
        return request({
            method:"POST",
            url:"/agent/pc/company/getStaffList",
            data:params
        })
    },
    // 打款审核
    payAudit(params){
        return request({
            method:'POST',
            url:'/payment/api/pay/audit',
            data: params,
        })
    },
    // 出账补单查询
    transferQuery(params){
        return request({
            method:'GET',
            url:'/payment/api/pay/transferQuery',
            params: params,
        })
    },
    // 入账补单查询
    refundQuery(params){
        return request({
            method:'GET',
            url:'/payment/api/pay/refundQuery',
            params: params,
        })
    },
    // 重新打款
    transferManual(params){
        return request({
            method:'GET',
            url:'/payment/api/pay/transferManual',
            params: params,
        })
    },
//报价修改信息列表
getUpdatedQuoteLog(params) {
    return request({
        method:'GET',
        url:'/agent/pc/order/getUpdatedQuoteLog',
        params
    })


},
// 利润排行榜
rankStatistics(params) {
    return request({
        method:'POST',
        url:'/agent/pc/storePc/statistics/rankStatistics',
        data: params
    })
},
// 利润排行榜下沉
rankStatisticsDown(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/storePc/statistics/rankStatisticsDown',  
        data: params
    })
},
// 订单成交额

echartsListOrder(params) {
    return request({
        method:'POST',
        url:'/agent/pc/storePc/statistics/getStatisticsOrderAmount',
        data: params
    })
},
// 订单统计

storechartsList(params) {
    return request({
        method:'POST',
        url:'/agent/pc/storePc/statistics/getStatisticsOrder',
        data: params
    })
},
// 店员数据
getOrderDeal(params) {
    return request({
        method:'POST',
        url:'/agent/pc/storePc/statistics/getStaffRankInStore',
        data: params
    })
},
    // 换新补贴-按门店统计
    getRenewStore (params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewStore',
            data: params
        })
    },
    // 换新补贴-按员工统计
    getRenewStaff (params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewStaff',
            data: params
        })
    },
    // 换新补贴-按换新商品统计
    getRenewProduct (params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewProduct',
            data: params
        })
    },
// 门店分析
statisticsorderDeal(params) {
    return request({
        method:'POST',
        url:'/agent/pc/storePc/statistics/orderDeal',
        data: params
    })
},
// 店员询价数据统计
getStaffInquiryStatistics(params) {
    return request({
        method:'POST',
        url:'/agent/pc/storePc/statistics/getStaffInquiryStatistics',
        data: params
    })
},
// 导出店员询价数据统计
getStaffInquiryExcel(params) {
    return request({
        method:'POST',
        url:'/agent/pc/storePc/statistics/getStaffInquiryExcel',
        data: params,
        responseType:'blob'
    })
},
// 撮合回收商下拉列表（查询）
matchMerchantList(params) {
    return request({
        method:'POST',
        url:'/agent/pc/matchChannelAccount/merchantList',
        data:params
    })
},
   // 各门店交易数据统计
   getStoreStatisticsRank(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/storePc/statistics/getStoreStatisticsRank',
        data: params
    })
},
// 导出各门店交易数据统计
getexcelStoreStatisticsRank(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/storePc/statistics/excelStoreStatisticsRank',
        data: params,
        responseType: 'blob'
    })
},
// 分润账单列表
matchChannelAccountList(params) {
    return request({
        method:'POST',
        url:'/agent/pc/matchChannelAccount/list',
        data:params
    })
},
// 分润账单导出Excel
matchChannelAccountExcel(params) {
    return request({
        method:'POST',
        url:'/agent/pc/matchChannelAccount/excel',
        data:params,
        responseType:'blob'
    })
},
// 加价详情
// /store/pc/order/addPriceInfo
pcaddPriceInfo(params) {
    return request({
        method:'GET',
        url:'/agent/pc/order/addPriceInfo',
        params
    })
},
//门店下拉列表
getStoreSelectList(params) {
    return request({
        method:'GET',
        url:'/agent/pc/storePc/statistics/getStoreSelectList',
        params
    })
},
//获取入驻时间戳
getEntryTime(params) {
    return request({
        method: 'GET',
        url: '/agent/pc/month/Statistics/getDate',
        params: params,
    })
},
//询价量、成交量环比数据
getInquiryDeal(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/month/Statistics/getInquiryDeal',
        data: params,
    })
},
//询价量、成交量环比数据
getCompanyBasic(params) {
    return request({
        method: 'GET',
        url: '/agent/pc/storePc/statistics/companyBasicData',
        params,
    })
},
//回收交易额、用户成交额&公司毛利环比数据
getDealFinalProfit(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/month/Statistics/getDealFinalProfit',
        data: params,
    })
},
//回收单价&成交客单价&单机毛利
getAvgPrice(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/month/Statistics/getAvgPrice',
        data: params,
    })
},
//公司毛利&员工奖励
getProfitReward(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/month/Statistics/getProfitReward',
        data: params,
    })
},
//毛利率环比数据
getProfitRate(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/month/Statistics/getProfitRate',
        data: params,
    })
},
//成交率、退款、取消率、换新率...
getMonthRate(params) {
    return request({
        method: 'POST',
        url: '/agent/pc/month/Statistics/getMonthRate',
        data: params,
    })
},
    // 门店商家列表
    getcompanylist(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/company/list',
            data: params
        })
    },
    // 省市区
    listAudit(params) {
        return request({
            method: 'GET',
            url: `/agent/pc/sys/city/listCityByParent/${params}`,
            params: params,
        })
    },
    //门店列表
    getStoreList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/company/getStoreList',
            data: params,
        })
    },
    //关联商家
    unionMerchantList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/company/unionMerchantList',
            data: params,
        })
    },
    // 订单状态下拉
    orderStateList(params = {}) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/orderStateList',
            params: params,
        })
    },
    // 旧机分类列表
    getAllMachineType(params = {}) {
        return request({
            method: 'GET',
            url: '/agent/pc/assembly/getAllMachineType',
            params: params,
        })
    },
    // 旧机品牌-下拉列表
    selectBrandList(params = {}) {
        return request({
            method: 'GET',
            url: '/agent/pc/assembly/selectBrandList',
            params: params,
        })
    },
    // Q-机型下拉列表
    listSelected(data) {
        return request({
            method: 'POST',
            url: '/agent/pc/assembly/listSelected',
            data,
        })
    },
    getHealthList(){
        return request.get("/agent/pc/assembly/getHealthList")
    },
    // 订单导出
    orderExcel(params = {}) {
        return request({
            method: 'POST',
            url: '/agent/pc/order/excel',
            data: params,
            responseType: 'blob'
        })
    },
    // 查看成交明细
    getQuoteCompute(orderNo){
        return request.get(`/agent/pc/order/getQuoteCompute?orderNo=${orderNo}`)
    },
    // 支付账单列表
    getAccountPayList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/account/detail/getList',
            data: params,
        })
    },
    //门店商家名称查询
    getrchantSelectList(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/company/getAllSelectList',
            params,
        })
    },
    // 账单子类型查询
    getSerTypeList(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/account/detail/getSerTypeList',
            params,
        })
    },
    // 账单导出
    billExportExcel(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/account/detail/exportExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // Excel-导出职员基础信息
    staffExcel(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/company/staffExcel',
            data: params,
            responseType: 'blob'
        })
    },
    getSelectList(params){
        return request({
            method: 'GET',
            url: '/agent/pc/company/getAllSelectList',
            params,
        })
    },
    //重复询价详情
    orderinfoRepeat(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/infoRepeat',
            params,
        })
    },
    // 加价详情
    // /store/pc/order/addPriceInfo
    orderaddPriceInfo(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/addPriceInfo',
            params: params
        })
    },
    // 内部加价详情
    insideApplyInfo(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/insideApplyInfo',
            params: params
        })
    },
    // Q-补拍查询
    listRemakeByOrderNo(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/order/listRemakeByOrderNo',
            params
        })
    },
    // 换购录音统计-按门店统计
    getRenewSoundStore(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewSoundStore',
            data: params,
        })
    },
    //导出换购录音统计数据(按门店统计)
    getRenewSoundStoreExcel(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewSoundStoreExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 换购录音统计-按店员统计
    getRenewSoundStaff(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewSoundStaff',
            data: params,
        })
    },
    //导出换购录音统计数据(按店员统计)
    getRenewSoundStaffExcel(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewSoundStaffExcel',
            data: params,
            responseType: 'blob'
        })
    },
    //导出换新补贴数据(按门店统计)
    getRenewStoreExcel(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewStoreExcel',
            data: params,
            responseType: 'blob'
        })
    },
    //换新补贴数据-按员工统计
    getRenewStaffExCEL(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/storePc/statistics/getRenewStaffExcel',
            data: params,
            responseType: 'blob'
        })
    },
    // 查询-门店首页标签下拉列表
    getStorePcTagSelect(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/storePc/statistics/getStoreTagSelect',
            params,
        })
    },
}
// -                         -------                    -- end -------------------------------------------------------------->
export default menu



